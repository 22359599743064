
























































import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    name: "Card",
})
export default class Card extends Vue {

  @Prop()
  protected id!: number;

  @Prop()
  protected name!: string;

  @Prop({default: false})
  protected selected!: boolean;

  @Prop({default: () => ({key: -1, name: 'Completed', icon: 'hat-wizard'})})
  protected type!: any;

  @Prop({default: () => ({id: 3, name: 'Completed', icon: 'hat-wizard', acronym:"C"})})
  protected status!: any;

  @Prop({default: false})
  protected highValueTarget!: boolean;

  @Prop({default: 1})
  protected reward!: number;

  @Prop({default: false})
  protected explore!: boolean;

  @Prop()
  protected heroimage?: string;

  @Prop({default: true})
  protected showHeroImage?: boolean;

  constructor() {
    super();
  }

  showDetails() {
    if(!this.explore)
      this.$emit("showCardDetails");
  }

  showInfoDetails() {
      this.$emit("showCardDetails");
  }

  acceptedCard() {
    this.$emit("assignToCard");
  }

  isSelected() {
    const value = this.selected;
    return value;
  }

  get typeIcon() {
    const icon = this.type.icon;
    return ['fa', icon];
  }

  get statusIcon() {
    if(this.status != undefined){
      const icon = this.status.icon;
      return ['fa', icon];
    }
      return ['fa', 'exclamation-circle'];
  }
}
